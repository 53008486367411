import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";

type ClientBanner = {
    title: string,
    content_encoded: string
}

const useGetClientBanner = () => {
    const token = useSelector(state => state.auth.token);

    const { isLoading, data, error } = useQuery<{ clientBanner: ClientBanner | null }>({
        queryKey: ['client_banner'],
        queryFn: async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/client-banner`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }),
            })

            if (!response.ok) {
                let error = await response.json()

                throw new Error(error.message)
            }

            return await response.json()
        },
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    return {isLoading, data, error}
}
export default useGetClientBanner;